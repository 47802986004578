import React from "react";
import logo from "../../assets/images/logo1.jpg"





const Footer = () => {
    const year = new Date().getFullYear()

    return (<footer className="bg-[#036666] pt-12 bottom-0 ">
        <div className="container bg-inherit overflow-hidden">
            <div className="sm:flex items-center justify-between md:gap-8 bg-inherit">
                <div className="w-full sm:w-1/2 bg-inherit">
                    <h2 className="text-[26px] leading-10 text-white font-[600] mb-5
                    md:text-[2rem] bg-inherit">Elevating Your Project Beyond Limits <span className="text-primaryColor bg-inherit">!!!</span></h2>
                </div>
                <div className="w-full sm:w-1/2 bg-inherit">
                    <p className="bg-inherit text-gray-300 leading-7 mt-4 sm:mt-0">
                        <strong>Location</strong> <br />43-KM. Multan Road, Lahore,
                        <br /> Pakistan.
                        <br />
                        <br />
                        <strong>Contact</strong>  <br />
                        +923218489389
                        <br />
                        +923274112367
                        <br />
                        +61466414499
                        <br />
                        abidminhaj@amcompillers.com
                        <br />
                        info@amcompillers.com
                    </p>
                </div>
            </div>
        </div>
        <div className="bg-[#036666] py-5 mt-14">
            <div className="container bg-inherit">
                <div className="flex items-center justify-center sm:justify-between bg-inherit">
                    <div className="hidden sm:block bg-inherit">
                        <a href="/" className="cursor-pointer">
                            <img className="w-20" src={logo} alt="logo" />
                        </a>
                    </div>
                    <div className="bg-inherit">
                        <p className="text-gray-400 text-[14px] bg-inherit">Copyright &copy; {year} by A & M Compillers - All rights reserved</p>
                    </div>

                </div>
            </div>
        </div>
    </footer>);
};

export default Footer;