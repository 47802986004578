const slider =[
    {
        id : "1",
        imgUrl : "https://gulffibre.com/images/stitch-bond1.jpg"
    },
    {
        id : "8",
        imgUrl : "https://gulffibre.com/images/stitch-bond2.jpg"
    },
    {
        id : "2",
        imgUrl : "https://gulffibre.com/images/stitch-bond3.jpg"
    },
    {
        id : "4",
        imgUrl : "https://gulffibre.com/images/stitch-bond4.jpg"
    },
    {
        id : "3",
        imgUrl : "https://gulffibre.com/images/stitch-bond5.jpg"
    },

    {
        id : "9",
        imgUrl : "https://gulffibre.com/images/stitch-bond6.jpg"
    },

    
    {
        id : "7",
        imgUrl : "https://gulffibre.com/images/stitch-bond10.jpg"
    },

    
    {
        id : "5",
        imgUrl : "https://gulffibre.com/images/stitch-bond8.jpg"
    },

]

export default slider