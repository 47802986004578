import React from 'react'
import Slider5 from '../side components/slider5'

const Uses = () => {
  return (
    <section className="pt-0 mt-10 scroll-smooth" id="products">
            <div className="container pt-14 ">
                <div className=" ">
                    <h1
                        data-aos="fade-up"
                        data-aos-duration="1500"
                        className='sm:text-[50px] text-primaryColor text-[1.6rem] font-[800] text-center'>
                        APPLICATIONS
                    </h1>



                    
                        <div className="w-full ">




                            <p
                                data-aos="fade-left"
                                data-aos-duration="1500"
                                className=" text-headingColor mt-8 font-[500] text-[20px] leading-7"
                            >
                                • Dams and Flood Defence Bunds
                                <br />
                                <br />
                                • River and Canal Bank Protection 
                                <br />
                                <br />
                                • River Revetments 
                                <br />
                                <br />
                                • Culvert Head Walls
                                <br />
                                <br />
                                • Cliff Protection 
                                <br />
                                <br />
                                • Bridge abutments 
                                <br />
                                <br />
                                • Submerged breakwaters
                                <br />
                                <br />
                                • Artificial Islands 

                            </p>

                        
                        
                    </div>
                </div>

                


                    <div className='mt-16'>
                {/* <h1
                        data-aos="fade-down"
                        data-aos-duration="1500"
                        className='sm:text-[50px]  text-primaryColor text-[1.6rem] font-[800] text-center'>
                        POLYESTER NON WOVEN FELTS
                    </h1> */}
                    <div
        data-aos='fade-left'
        data-aos-duration='2200'
        data-aos-delay='400' 
        className='  max-w-[1170px] '>
           <div className='flex-col columns-2  w-[70%] m-auto  mt-7 '>
                {
                    Slider5?.map((slider5) =>(
                            <div className='flex-col   mb-10'>
                                <img  className='w-[120px] block m-auto h-[120px]  lg:w-[200px] lg:h-[200px] object-cover'  src={slider5.imgUrl} alt="/" />
                            </div>
                    ))
                }
           </div>
           </div>
                    </div>

























                {/* <div className=" mt-16">
                    <h1
                        data-aos="fade-down"
                        data-aos-duration="1500"
                        className='sm:text-[50px] text-primaryColor font-[800] text-center text-[1.6rem]'>
                        Step 2
                    </h1>



                    <div className="md:flex items-center justify-between sm:flex flex-col md:flex-row mt-10">
                        <div className="w-full md:basis-1/2">



                            <h2 data-aos="fade-left"
                                data-aos-duration="1500"
                                className="flex gap-2 sm:text-[35px] text-primaryColor font-[700] mt-8 sm:pl-10 text-[20px] leading-7  sm:pr-10 text-justify">
                                STAPLE FIBER PLANTS
                            </h2>
                            <p
                                data-aos="fade-left"
                                data-aos-duration="1500"
                                className="flex gap-2 text-headingColor mt-8 font-[500] text-[20px] leading-7  sm:pr-10 text-justify"
                            >
                                A & M Compillers boasts compact, cutting-edge 1-step short spinning lines, renowned for their remarkable adaptability when it comes to processing raw materials such as PET, R-PET, PP, and PA6, covering an extensive range of fiber thickness.
                                <br />
                                <br />
                                These state-of-the-art facilities facilitate an exceptionally cost-effective fiber production for a diverse array of subsequent applications, including luxurious carpets and exquisite textiles.

                            </p>
                            <h2 data-aos="fade-left"
                                data-aos-duration="1500"
                                className="flex gap-2 sm:text-[30px] text-smallTextColor font-[600] mt-8 underline text-[20px] leading-7  sm:pr-10 text-justify">
                                STAPLE FIBER PLANTS
                            </h2>
                            <p
                                data-aos="fade-left"
                                data-aos-duration="1500"
                                className="flex gap-2 text-headingColor mt-8 font-[500] text-[20px] leading-7  sm:pr-10 text-justify"
                            >
                                <strong>You want:</strong>
                                <br />
                                <br />
                                … to flexibly produce different products?<br />

                                … to produce economically small lots?<br />

                                ... to reduce waste during product changes?<br />
                                … to develop new products?<br />
                                … to have more influence on our fiber quality?<br />
                                … to be independent?<br />
                                … a small compact plant?<br />
                                <br />
                                    In this case Gulf Fibre (Pvt) Ltd. has developed the new Staple FORCE S 1000 for you. The result is a staple fiber plant unsurpassed in terms of flexibility and economy.


                            </p>

                        </div>
                        <div className="basis-1/3 mt-10 sm:mt-0">
                            <figure
                                data-aos="fade-right"
                                data-aos-duration="1500"
                                className="flex items-center justify-center">
                                <img className='rounded-2xl' src="https://vnpolyfiber.com/wp-content/uploads/2021/02/VNPOLYFIBER-Stretcher-PSF.jpg" alt="" />
                            </figure>
                        </div>
                    </div>
                </div> */}



            </div>
        </section>
  )
}

export default Uses
