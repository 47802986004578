


const slider1 =[
    {
        id : "1",
        imgUrl : "https://gulffibre.com/images/Polyester-Wadding2.gif"
    },
    {
        id : "8",
        imgUrl : "https://gulffibre.com/images/Polyester-Wadding5.jpg"
    },
    {
        id : "2",
        imgUrl : "https://gulffibre.com/images/Polyester-Wadding3.jpg"
    },
    {
        id : "4",
        imgUrl : "https://gulffibre.com/images/polysterwadding1.jpg"
    },
    {
        id : "3",
        imgUrl : "https://gulffibre.com/images/Polyester-Wadding4.jpg"
    },

    {
        id : "9",
        imgUrl : "https://gulffibre.com/images/Polyester-Wadding6.jpg"
    },

    
]

export default slider1