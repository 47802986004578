import React, { useRef, useState, useEffect } from "react";
import logo from "../../assets/images/logo1.jpg";
import { SlMenu, SlClose } from "react-icons/sl";
import { NavLink } from "react-router-dom";
import pdf from "../../assets/pdf/Broucher Geo-Textile Construction.pdf";
import iso from "../../assets/images/iso.jpg";
import pdf2 from '../../assets/pdf/geotextiles.pdf'

const Header = () => {
  const headerRef = useRef(null);
  const menuRef = useRef(null);
  const [showIso, setShowIso] = useState(false);

  const toggleMenu = () => menuRef.current.classList.toggle("show_menu");
  const toggleIso = () => setShowIso(!showIso);

  useEffect(() => {
    if (showIso) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [showIso]);

  return (
    <header ref={headerRef} className="w-full h-[80px] leading-[80px] flex items-center bgc">
      <div className="container">
        <div className="flex items-center justify-between">
          <a href="/" className="cursor-pointer">
            <img className="w-36 pl-[-20px]" src={logo} alt="logo" />
          </a>
          <div className="flex items-center gap-4">
            <div className="menu" ref={menuRef} onClick={toggleMenu}>
              <ul className="flex items-center gap-10 ml-auto">
                <li>
                  <NavLink className="text-smallTextColor navi font-[600] transition duration-200 hover:text-primaryColor" to="/">Home</NavLink>
                </li>
                <li>
                  <NavLink className="text-smallTextColor navi font-[600] transition duration-200 hover:text-primaryColor" to="/aboutus">About</NavLink>
                </li>
                <li>
                  <button onClick={toggleIso} className="text-smallTextColor navi font-[600] transition duration-200 hover:text-primaryColor">ISO</button>
                </li>
                <li>
                  <NavLink className="text-smallTextColor navi font-[600] transition duration-200 hover:text-primaryColor" to="/technology">Technology</NavLink>
                </li>
                <li>
                  <NavLink className="text-smallTextColor navi font-[600] transition duration-200 hover:text-primaryColor" to="/products">Products</NavLink>
                </li>
                <li>
                  <NavLink className="text-smallTextColor navi font-[600] transition duration-200 hover:text-primaryColor" to="/uses">Uses</NavLink>
                </li>
                <li>
                  <NavLink className="text-smallTextColor navi font-[600] transition duration-200 hover:text-primaryColor" to="/contact">Contact</NavLink>
                </li>
                <li>
                  <a href={pdf} download="Geo Textiles" className="text-smallTextColor navi font-[600] transition duration-200 hover:text-primaryColor">Geotextiles</a>
                </li>
                <li>
                  <a href={pdf2} download="More about geotextiles" className="text-smallTextColor navi font-[600] transition duration-200 hover:text-primaryColor">More About Geotextiles</a>
                </li>
              </ul>
            </div>
            <span onClick={toggleMenu} className="lg:hidden md:hidden cursor-pointer">
              <SlMenu className="w-[35px] h-[35px] text-primaryColor" />
            </span>
          </div>
        </div>
      </div>

      {showIso && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-75">
          <div className="relative p-4 w-full h-full max-w-4xl max-h-[80vh] flex items-center justify-center">
            <img src={iso} alt="ISO" className="max-w-full max-h-full object-contain" />
            <button onClick={toggleIso} className="absolute top-4 right-4 text-white text-2xl bg-black rounded-full p-1">
              <SlClose />
            </button>
          </div>
        </div>
      )}
    </header>
  );
};

export default Header;
