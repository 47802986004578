import React from 'react'


const Technology = () => {
    return (
        <section className="pt-0 mt-10 scroll-smooth" id="technology">
            <div className="container pt-14 ">
                <div className=" ">
                    <h1
                        data-aos="fade-up"
                        data-aos-duration="1500"
                        className='sm:text-[50px] text-primaryColor text-[1.6rem] font-[800] text-center'>
                        Step 1
                    </h1>



                    <div className="md:flex items-center justify-between sm:flex flex-col md:flex-row mt-10">
                        <div className="w-full md:basis-1/2">



                            <h2 data-aos="fade-left"
                                data-aos-duration="1500"
                                className="flex gap-2 sm:text-[35px] text-primaryColor font-[700] mt-8 sm:pl-10 text-[20px] leading-7  sm:pr-10 text-justify">
                                STAPLE FIBER PLANTS
                            </h2>
                            <p
                                data-aos="fade-left"
                                data-aos-duration="1500"
                                className="flex gap-2 text-headingColor mt-8 font-[500] text-[20px] leading-7  sm:pr-10 text-justify"
                            >
                                A & M Compilers boasts compact, cutting-edge 1-step short spinning lines, renowned for their remarkable adaptability when it comes to processing raw materials such as PET, R-PET, PP, and PA6, covering an extensive range of fiber thickness. These state-of-the-art facilities facilitate an exceptionally cost-effective fiber production for a diverse array of subsequent applications, including luxurious carpets and exquisite textiles.
                            </p>

                        </div>
                        <div className="basis-1/3 mt-10 sm:mt-0">
                            <figure
                                data-aos="fade-right"
                                data-aos-duration="1500"
                                className="flex items-center justify-center">
                                <img className='rounded-2xl' src="https://s.alicdn.com/@sc04/kf/H9ac2c940a10340ad957f85f3236dfb288.jpg_960x960.jpg" alt="" />
                            </figure>
                        </div>
                    </div>
                </div>



                <div className=" mt-16">
                    <h1
                        data-aos="fade-down"
                        data-aos-duration="1500"
                        className='sm:text-[50px] text-primaryColor font-[800] text-center text-[1.6rem]'>
                        Step 2
                    </h1>



                    <div className="md:flex items-center justify-between sm:flex flex-col md:flex-row mt-10">
                        <div className="w-full md:basis-1/2">



                            <h2 data-aos="fade-left"
                                data-aos-duration="1500"
                                className="flex gap-2 sm:text-[35px] text-primaryColor font-[700] mt-8 sm:pl-10 text-[20px] leading-7  sm:pr-10 text-justify">
                                STAPLE FIBER PLANTS
                            </h2>
                            <p
                                data-aos="fade-left"
                                data-aos-duration="1500"
                                className="flex gap-2 text-headingColor mt-8 font-[500] text-[20px] leading-7  sm:pr-10 text-justify"
                            >
                                A & M Compillers boasts compact, cutting-edge 1-step short spinning lines, renowned for their remarkable adaptability when it comes to processing raw materials such as PET, R-PET, PP, and PA6, covering an extensive range of fiber thickness.
                                <br />
                                <br />
                                These state-of-the-art facilities facilitate an exceptionally cost-effective fiber production for a diverse array of subsequent applications, including luxurious carpets and exquisite textiles.

                            </p>
                            <h2 data-aos="fade-left"
                                data-aos-duration="1500"
                                className="flex gap-2 sm:text-[30px] text-smallTextColor font-[600] mt-8 underline text-[20px] leading-7  sm:pr-10 text-justify">
                                STAPLE FIBER PLANTS
                            </h2>
                            <p
                                data-aos="fade-left"
                                data-aos-duration="1500"
                                className="flex gap-2 text-headingColor mt-8 font-[500] text-[20px] leading-7  sm:pr-10 text-justify"
                            >
                                <strong>You want:</strong>
                                <br />
                                <br />
                                … to flexibly produce different products?<br />

                                … to produce economically small lots?<br />

                                ... to reduce waste during product changes?<br />
                                … to develop new products?<br />
                                … to have more influence on our fiber quality?<br />
                                … to be independent?<br />
                                … a small compact plant?<br />
                                <br />
                                    In this case Gulf Fibre (Pvt) Ltd. has developed the new Staple FORCE S 1000 for you. The result is a staple fiber plant unsurpassed in terms of flexibility and economy.


                            </p>

                        </div>
                        <div className="basis-1/3 mt-10 sm:mt-0">
                            <figure
                                data-aos="fade-right"
                                data-aos-duration="1500"
                                className="flex items-center justify-center">
                                <img className='rounded-2xl' src="https://vnpolyfiber.com/wp-content/uploads/2021/02/VNPOLYFIBER-Stretcher-PSF.jpg" alt="" />
                            </figure>
                        </div>
                    </div>
                </div>


                <div className=" mt-16">
                    <h1
                        data-aos="fade-down"
                        data-aos-duration="1500"
                        className='sm:text-[50px] text-primaryColor font-[800] text-center text-[1.6rem]'>
                        Process
                    </h1>



                    <div className="block mr-auto ml-auto">
                        <div className="mt-4 block mr-auto ml-auto">
                            <figure
                                data-aos="fade-right"
                                data-aos-duration="1500"
                                className="block mr-auto ml-auto">
                                <img className='rounded-2xl block mr-auto ml-auto' src="https://gulffibre.com/images/process.jpg" alt="process" />
                            </figure>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Technology
