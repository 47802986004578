const slider =[
    {
        id : "1",
        imgUrl : "https://gulffibre.com/images/fusing-interlining-1.jpg"
    },
    {
        id : "8",
        imgUrl : "https://gulffibre.com/images/fusing-interlining-2.jpg"
    },
    {
        id : "2",
        imgUrl : "https://gulffibre.com/images/fusing-interlining-3.jpg"
    },
    {
        id : "4",
        imgUrl : "https://gulffibre.com/images/fusing-interlining-4.jpg"
    },
    {
        id : "3",
        imgUrl : "https://gulffibre.com/images/fusing-interlining-6.jpg"
    },

    {
        id : "9",
        imgUrl : "https://gulffibre.com/images/fusing-interlining-7.jpg"
    },

    
    {
        id : "7",
        imgUrl : "https://gulffibre.com/images/fusing-interlining-10.jpg"
    },

    
    {
        id : "5",
        imgUrl : "https://gulffibre.com/images/fusing-interlining-9.jpg"
    },

]

export default slider