import React from 'react'
import Slider1 from '../side components/slider1';
import Slider2 from '../side components/slider2';
import Slider3 from '../side components/slider3';
import Slider4 from '../side components/slider4';

const Products = () => {
    return (
        <section className="pt-0 mt-10 scroll-smooth" id="products">
            <div className="container pt-14 ">
                <div className=" ">
                    <h1
                        data-aos="fade-up"
                        data-aos-duration="1500"
                        className='sm:text-[50px] text-primaryColor text-[1.6rem] font-[800] text-center'>
                        RECYCLED POLYESTER STAPLE FIBER SPECIFICATIONS
                    </h1>



                    <div className="md:flex items-center justify-between sm:flex flex-col md:flex-row mt-10">
                        <div className="w-full">



                            <h2 data-aos="fade-left"
                                data-aos-duration="1500"
                                className="flex gap-2 sm:text-[35px] text-smallTextColor font-[700] mt-8 sm:pl-10 text-[20px] leading-7  sm:pr-10 text-justify">
                                BRIGHT SEMI DULL
                            </h2>
                            <p
                                data-aos="fade-left"
                                data-aos-duration="1500"
                                className=" text-headingColor mt-8 font-[500] text-[20px] leading-7  sm:pr-10 text-justify"
                            >
                                <tr>
                                    <td>Denier : &nbsp;</td>
                                    <td> 1.4 Den to 15 Den</td>
                                </tr>
                                <tr>
                                    <td>Cut Length : &nbsp;</td>
                                    <td> 25mm , to 100mm</td>
                                </tr>
                                <tr>
                                    <td>Tenacity : &nbsp;</td>
                                    <td> 4.2</td>
                                </tr><tr>
                                    <td>Elongation : &nbsp;</td>
                                    <td> as per</td>
                                </tr><tr>
                                    <td>Oil Pick-up : &nbsp;</td>
                                    <td> 0.10%</td>
                                </tr><tr>
                                    <td>Colour : &nbsp;</td>
                                    <td> Bright, semi dull, Raw white
                                    </td>
                                </tr>

                            </p>

                        </div>
                        <div className="basis-1/3 mt-10 sm:mt-0">
                            <figure
                                data-aos="fade-right"
                                data-aos-duration="1500"
                                className="flex items-center justify-center">
                                <img className='rounded-2xl' src="https://gulffibre.com/images/recycle2.jpg" alt="" />
                            </figure>
                        </div>
                    </div>
                </div>

                <div className=" ">
                    <div className="md:flex items-center justify-between sm:flex flex-col md:flex-row mt-10">
                        <div className="w-full">



                            <h2 data-aos="fade-left"
                                data-aos-duration="1500"
                                className="flex gap-2 sm:text-[35px] text-smallTextColor font-[700] mt-8 sm:pl-10 text-[20px] leading-7  sm:pr-10 text-justify">

                                TEXTILE GRADE FIBER - BLACK & DOPE DYED SHADES
                            </h2>
                            <p
                                data-aos="fade-left"
                                data-aos-duration="1500"
                                className=" text-headingColor mt-8 font-[500] text-[20px] leading-7  sm:pr-10 text-justify"
                            >
                                <tr>
                                    <td>Denier : &nbsp;</td>
                                    <td> 1.4 Dn, 1.7 Dn</td>
                                </tr>
                                <tr>
                                    <td>Cut Length : &nbsp;</td>
                                    <td> 32, 38, 44, 51mm</td>
                                </tr>
                                <tr>
                                    <td>Tenacity : &nbsp;</td>
                                    <td>  Above 5.5 GPD</td>
                                </tr><tr>
                                    <td>Elongation : &nbsp;</td>
                                    <td> 25% to 35%</td>
                                </tr><tr>
                                    <td>Oil Pick-up : &nbsp;</td>
                                    <td> 0.10 to 0.20%</td>
                                </tr><tr>
                                    <td>Colour : &nbsp;</td>
                                    <td> Black/ Blue/ Brown etc.
                                    </td>
                                </tr>

                            </p>

                        </div>
                        <div className="basis-1/3 mt-10 sm:mt-0">
                            <figure
                                data-aos="fade-right"
                                data-aos-duration="1500"
                                className="flex items-center justify-center">
                                <img className='rounded-2xl' src="https://gulffibre.com/images/recycle3.jpg" alt="" />
                            </figure>
                        </div>
                    </div>
                </div>


                <div className=" ">
                    <div className="md:flex items-center justify-between sm:flex flex-col md:flex-row mt-10">
                        <div className="w-full">



                            <h2 data-aos="fade-left"
                                data-aos-duration="1500"
                                className="flex gap-2 sm:text-[35px] text-smallTextColor font-[700] mt-8 sm:pl-10 text-[20px] leading-7  sm:pr-10 text-justify">

                                SOLID POLYESTER STAPLE FIBER
                            </h2>
                            <p
                                data-aos="fade-left"
                                data-aos-duration="1500"
                                className=" text-headingColor mt-8 font-[500] text-[20px] leading-7  sm:pr-10 text-justify"
                            >
                                <tr>
                                    <td>Denier : &nbsp;</td>
                                    <td> 6 Dn - 15 Dn</td>
                                </tr>
                                <tr>
                                    <td>Cut Length : &nbsp;</td>
                                    <td> 64mm</td>
                                </tr>
                                <tr>
                                    <td>Tenacity : &nbsp;</td>
                                    <td>  Above 4.0 GPD</td>
                                </tr><tr>
                                    <td>Quality  : &nbsp;</td>
                                    <td> Dry </td>
                                </tr><tr>
                                    <td>Oil Pick-up : &nbsp;</td>
                                    <td> 0.10 to 0.20%</td>
                                </tr><tr>
                                    <td>Colour : &nbsp;</td>
                                    <td> Super White/Off-White/Green/Red
                                    </td>
                                </tr>

                            </p>

                        </div>
                        <div className="basis-1/3 mt-10 sm:mt-0">
                            <figure
                                data-aos="fade-right"
                                data-aos-duration="1500"
                                className="flex items-center justify-center">
                                <img className='rounded-2xl' src="https://gulffibre.com/images/recycle6.jpg" alt="" />
                            </figure>
                        </div>
                    </div>
                </div>


                <div className=" ">
                    <div className="md:flex items-center justify-between sm:flex flex-col md:flex-row mt-10">
                        <div className="w-full">



                            <h2 data-aos="fade-left"
                                data-aos-duration="1500"
                                className="flex gap-2 sm:text-[35px] text-smallTextColor font-[700] mt-8 sm:pl-10 text-[20px] leading-7  sm:pr-10 text-justify">
                                POLYESTER STAPLE FIBER, HOLLOW MECHANICAL CRIMPED
                            </h2>
                            <p
                                data-aos="fade-left"
                                data-aos-duration="1500"
                                className=" text-headingColor mt-8 font-[500] text-[20px] leading-7  sm:pr-10 text-justify"
                            >
                                <tr>
                                    <td>Denier : &nbsp;</td>
                                    <td> 6 Dn - 15 Dn</td>
                                </tr>
                                <tr>
                                    <td>Cut Length : &nbsp;</td>
                                    <td> 64mm</td>
                                </tr>
                                <tr>
                                    <td>Hollowness : &nbsp;</td>
                                    <td>  20% to 25%</td>
                                </tr><tr>
                                    <td>Quality  : &nbsp;</td>
                                    <td> Dry / Siliconise </td>
                                </tr><tr>
                                    <td>Oil Pick-up : &nbsp;</td>
                                    <td> 0.10 to 0.20%</td>
                                </tr><tr>
                                    <td>Colour : &nbsp;</td>
                                    <td> Super White/Off-White
                                    </td>
                                </tr>

                            </p>

                        </div>
                        <div className="basis-1/3 mt-10 sm:mt-0">
                            <figure
                                data-aos="fade-right"
                                data-aos-duration="1500"
                                className="flex items-center justify-center">
                                <img className='rounded-2xl' src="https://gulffibre.com/images/recycle8.jpg" alt="" />
                            </figure>
                        </div>
                    </div>
                </div>


                <div className='mt-16'>
                <h1
                        data-aos="fade-up"
                        data-aos-duration="1500"
                        className='sm:text-[50px] text-primaryColor text-[1.6rem] font-[800] text-center'>
                        POLYESTER WADDING
                    </h1>
                    <div
        data-aos='fade-right'
        data-aos-duration='2200'
        data-aos-delay='400' 
        className='  max-w-[1170px] '>
           <div className='flex-col columns-2  w-[70%] m-auto  mt-7 '>
                {
                    Slider1?.map((slider1) =>(
                            <div className='flex-col   mb-10'>
                                <img  className='w-[120px] block m-auto h-[120px]  lg:w-[200px] lg:h-[200px] object-cover'  src={slider1.imgUrl} alt="/" />
                            </div>
                    ))
                }
           </div>
           </div>
                    </div>



                    <div className='mt-16'>
                <h1
                        data-aos="fade-down"
                        data-aos-duration="1500"
                        className='sm:text-[50px] text-primaryColor text-[1.6rem] font-[800] text-center'>
                        POLYESTER NON WOVEN INTERLINING
                    </h1>
                    <div
        data-aos='fade-left'
        data-aos-duration='2200'
        data-aos-delay='400' 
        className='  max-w-[1170px] '>
           <div className='flex-col columns-2  w-[70%] m-auto  mt-7 '>
                {
                    Slider2?.map((slider2) =>(
                            <div className='flex-col   mb-10'>
                                <img  className='w-[120px] block m-auto h-[120px]  lg:w-[200px] lg:h-[200px] object-cover'  src={slider2.imgUrl} alt="/" />
                            </div>
                    ))
                }
           </div>
           </div>
                    </div>


                    <div className='mt-16'>
                <h1
                        data-aos="fade-up"
                        data-aos-duration="1500"
                        className='sm:text-[50px]  text-primaryColor text-[1.6rem] font-[800] text-center'>
                        STITCH BONDED INTERLINING
                    </h1>
                    <div
        data-aos='fade-right'
        data-aos-duration='2200'
        data-aos-delay='400' 
        className='  max-w-[1170px] '>
           <div className='flex-col columns-2  w-[70%] m-auto  mt-7 '>
                {
                    Slider3?.map((slider3) =>(
                            <div className='flex-col   mb-10'>
                                <img  className='w-[120px] block m-auto h-[120px]  lg:w-[200px] lg:h-[200px] object-cover'  src={slider3.imgUrl} alt="/" />
                            </div>
                    ))
                }
           </div>
           </div>
                    </div>


                    <div className='mt-16'>
                <h1
                        data-aos="fade-down"
                        data-aos-duration="1500"
                        className='sm:text-[50px]  text-primaryColor text-[1.6rem] font-[800] text-center'>
                        POLYESTER NON WOVEN FELTS
                    </h1>
                    <div
        data-aos='fade-left'
        data-aos-duration='2200'
        data-aos-delay='400' 
        className='  max-w-[1170px] '>
           <div className='flex-col columns-2  w-[70%] m-auto  mt-7 '>
                {
                    Slider4?.map((slider4) =>(
                            <div className='flex-col   mb-10'>
                                <img  className='w-[120px] block m-auto h-[120px]  lg:w-[200px] lg:h-[200px] object-cover'  src={slider4.imgUrl} alt="/" />
                            </div>
                    ))
                }
           </div>
           </div>
                    </div>

























                {/* <div className=" mt-16">
                    <h1
                        data-aos="fade-down"
                        data-aos-duration="1500"
                        className='sm:text-[50px] text-primaryColor font-[800] text-center text-[1.6rem]'>
                        Step 2
                    </h1>



                    <div className="md:flex items-center justify-between sm:flex flex-col md:flex-row mt-10">
                        <div className="w-full md:basis-1/2">



                            <h2 data-aos="fade-left"
                                data-aos-duration="1500"
                                className="flex gap-2 sm:text-[35px] text-primaryColor font-[700] mt-8 sm:pl-10 text-[20px] leading-7  sm:pr-10 text-justify">
                                STAPLE FIBER PLANTS
                            </h2>
                            <p
                                data-aos="fade-left"
                                data-aos-duration="1500"
                                className="flex gap-2 text-headingColor mt-8 font-[500] text-[20px] leading-7  sm:pr-10 text-justify"
                            >
                                A & M Compillers boasts compact, cutting-edge 1-step short spinning lines, renowned for their remarkable adaptability when it comes to processing raw materials such as PET, R-PET, PP, and PA6, covering an extensive range of fiber thickness.
                                <br />
                                <br />
                                These state-of-the-art facilities facilitate an exceptionally cost-effective fiber production for a diverse array of subsequent applications, including luxurious carpets and exquisite textiles.

                            </p>
                            <h2 data-aos="fade-left"
                                data-aos-duration="1500"
                                className="flex gap-2 sm:text-[30px] text-smallTextColor font-[600] mt-8 underline text-[20px] leading-7  sm:pr-10 text-justify">
                                STAPLE FIBER PLANTS
                            </h2>
                            <p
                                data-aos="fade-left"
                                data-aos-duration="1500"
                                className="flex gap-2 text-headingColor mt-8 font-[500] text-[20px] leading-7  sm:pr-10 text-justify"
                            >
                                <strong>You want:</strong>
                                <br />
                                <br />
                                … to flexibly produce different products?<br />

                                … to produce economically small lots?<br />

                                ... to reduce waste during product changes?<br />
                                … to develop new products?<br />
                                … to have more influence on our fiber quality?<br />
                                … to be independent?<br />
                                … a small compact plant?<br />
                                <br />
                                    In this case Gulf Fibre (Pvt) Ltd. has developed the new Staple FORCE S 1000 for you. The result is a staple fiber plant unsurpassed in terms of flexibility and economy.


                            </p>

                        </div>
                        <div className="basis-1/3 mt-10 sm:mt-0">
                            <figure
                                data-aos="fade-right"
                                data-aos-duration="1500"
                                className="flex items-center justify-center">
                                <img className='rounded-2xl' src="https://vnpolyfiber.com/wp-content/uploads/2021/02/VNPOLYFIBER-Stretcher-PSF.jpg" alt="" />
                            </figure>
                        </div>
                    </div>
                </div> */}



            </div>
        </section>
    )
}

export default Products
