import React, { useEffect, useState } from 'react';
import num1 from '../../assets/images/num1.png'


const Hero = () => {
  const images = ["https://gulffibre.com/images/staple-fibre.jpg", "https://gulffibre.com/images/slider2.jpg", "https://gulffibre.com/images/Polyester-Needled-Felts1.jpg", "https://gulffibre.com/images/PolyesterWadding1.jpg"];
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 2000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <section className="pt-0 bgc" id="about">
      <div className="container pt-14 min-h-screen">
        <div className="md:flex items-center justify-between sm:flex flex-col md:flex-row">
          <div className="w-full md:basis-1/2">

            <h1
              data-aos="fade-up"
              data-aos-duration="1500"
              className="text-headingColor font-[900] text-[1.8rem] sm:text-[30px] leading-[35px] sm:leading-[46px] mt-5 w-fit h-fit overflow-hidden"
            >
              Let us <br />
              <span className='sm:text-[50px] text-primaryColor'>
                Indulge in Fiber Arts
              </span>
            </h1>

            <p
              data-aos="fade-left"
              data-aos-duration="1500"
              className=" gap-2 text-headingColor mt-8 font-[500] sm:pl-10 text-[20px] leading-7  sm:pr-10 text-justify"
            >
              A global leader in recycled polyster staple fiber and nonwoven solutions, setting new industry standards with innovative
              products and unwavering customer focus. 
            </p>

          </div>
          <div className="basis-1/3 mt-10 sm:mt-0">
            <figure
              data-aos="fade-right"
              data-aos-duration="1500"
              className="flex items-center justify-center">
              {images.map((img, index) => (
                <img
                  key={index}
                  className={`rounded-2xl shadow-2xl ${index === currentIndex ? 'block' : 'hidden'
                    }`}
                  src={img}
                  alt=""
                />
              ))}
            </figure>
          </div>
        </div>
        <div className=" mt-24 text-[1.6rem]">
          <h2
            data-aos="fade-up"
            data-aos-duration="1500"
            className='sm:text-[40px] text-primaryColor font-[700] text-center'>
            Our Vision
          </h2>



          <div className="md:flex items-center justify-between sm:flex flex-col md:flex-row mt-10">
            <div className="w-full md:basis-1/2">




              <p
                data-aos="fade-left"
                data-aos-duration="1500"
                className="flex gap-2 text-headingColor mt-8 font-[600] text-[20px] italic leading-7  sm:pr-10 text-justify"
              >
                "As a distinguished leader in non-woven solutions, felts, geo-textiles,
                bedding expertise, and mattresses, we deliver pioneering solutions worldwide.
                Our commitment to innovation sets us apart, consistently surpassing expectations
                and pushing boundaries. With a global presence and unwavering dedication to customer satisfaction,
                we redefine industry standards, providing cutting-edge non-woven solutions for diverse industries."
              </p>

            </div>
            <div className="basis-1/3 mt-10 sm:mt-0">
              <figure
                data-aos="fade-right"
                data-aos-duration="1500"
                className="flex items-center justify-center">
                <img className='rounded-br-[90px] shadow-lg shadow-gray-600' src="https://gulffibre.com/images/about-ushome.jpg" alt="" />
              </figure>
            </div>
          </div>
        </div>


        <div className=" mt-24 text-[1.6rem]">
          <h2
            data-aos="fade-down"
            data-aos-duration="1500"
            className='sm:text-[40px] text-primaryColor font-[700] text-center'>
            Our Mission
          </h2>



          <div className="md:flex items-center justify-between sm:flex flex-col md:flex-row mt-10">
            <div className="w-full md:basis-1/2">




              <p
                data-aos="fade-left"
                data-aos-duration="1500"
                className="flex gap-2 text-headingColor mt-8 font-[500] text-[20px]  leading-7 sm:pr-10 text-justify"
              >

                We strive to become the preferred choice for our valued customers
                by offering unparalleled service and continually advancing our
                cutting-edge polyester fiber technologies to meet their future requirements.
                Our state-of-the-art spinning techniques make us a leading provider of top-quality
                staple fibers, widely acclaimed in the fashion, home decor, and industrial sectors.
                Thanks to our esteemed reputation, our polyester staple fiber range showcases revolutionary
                products that redefine the industry. Our supernatural microfiber has revolutionized the
                perception of polyester staple fiber, while our optic white, hollow conjugated polyester
                fiber and ultra-fine denier polyester staple fiber blend exquisite softness with exceptional durability.
              </p>

            </div>
            <div className="basis-1/3 mt-10 sm:mt-0 lg:flex hidden md:flex ">
              <figure
                data-aos="fade-right"
                data-aos-duration="1500"
                className="flex items-center justify-center">
                <img src={num1} alt="" />
              </figure>
            </div>
          </div>
        </div>
        {/* <h2 className='text-headingColor text-center sm:text-[40px] font-[700] mt-24 '>Our global marketing partner is <span className='text-primaryColor italic'>"VERTIGO FIBERS"</span></h2 > */}
      </div>
    </section>
  );
};

export default Hero;
