import React, {useRef} from 'react'
import emailjs from '@emailjs/browser';
import toast from 'react-hot-toast';
import Swal from 'sweetalert2';

const Contact = () => {

  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
    .sendForm('service_5c26a0a', 'template_x78vcsp', form.current, 'Y0AFR7u3UvQaqPyK9')
      .then((result) => {
          console.log(result.text);
          form.current.reset();
          toast.success(Swal.fire({ title:'Message Sent Successfully',
          confirmButtonColor: "#036666"}));
      }, (error) => {
          console.log(error.text);
          toast.error(error.text);
      });
  };
  return (
    <section id='contact' className='pb-16 bgc mt-10'>
      <div
      className='container min-h-screen'>
        <h2
        data-aos='fade-up'
        data-aos-duration='1200'
        data-aos-delay='200'
        className='text-headingColor font-[700] text-[2.5rem] mb-8'>Catch Us <span className='text-primaryColor'>!</span> </h2>
        <div className="flex justify-center items-center">
            {/* <div
            data-aos='fade-right'
            data-aos-duration='1500'
            data-aos-delay='300' 
            className="w-full md:w-1/2 h-[300px] sm:h-[450px]">
            <iframe title='google-maps'
 src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d413.99183704524256!2d74.07763649393387!3d31.303137681754617!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2s!4v1688805009459!5m2!1sen!2s"
 className='border-0 w-full h-full ' allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>


            </div> */}
            <div
            data-aos='fade-left'
            data-aos-duration='1500'
            data-aos-delay='300' 
            className="w-full sm:mt-14 md:mt-0 md:w-1/2 sm:h-[450px] lg:flex items-center  bg-[#036666]
            px-4 lg:px-8 py-8">
                <form className='w-full bg-inherit' ref={form} onSubmit={sendEmail}>
                        <input type="text" placeholder='Enter Your Name' className='mb-5 w-full p-3 focus:outline-none
                        rounded-[5px] ' name='from_name' required/>
                        <input type="Email" placeholder='Enter Your Email' className='mb-5 w-full p-3 focus:outline-none
                        rounded-[5px] ' name='user_email' 
                        pattern='[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{1,63}$' required />
                        <textarea type="text" rows={3} placeholder='Write Your Message' className='mb-5 w-full p-3 focus:outline-none
                        rounded-[5px]' name='message' required />
                    <button className='w-full p-3 focus:outline-none rounded-[5px]
                    bg-primaryColor text-white hover:bg-headingColor text-center ease-linear
                    duration-150'>Send</button>
                </form>
            </div>
        </div>
      </div>
    </section >
  )
}

export default Contact
