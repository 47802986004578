import React from 'react';
import logo from "../../assets/images/logo1.jpg"

const Loader = () => {
    return (
        <div className="flex justify-center items-center h-screen">
           
           <img className='load w-[30vh]' src={logo} alt="" />
        </div>

    );
};

export default Loader;