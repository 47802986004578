const slider =[
    {
        id : "1",
        imgUrl : "https://gulffibre.com/images/poleyster-felts1.jpg"
    },
    {
        id : "8",
        imgUrl : "https://gulffibre.com/images/poleyster-felts2.jpg"
    },
    {
        id : "2",
        imgUrl : "https://gulffibre.com/images/poleyster-felts3.jpg"
    },
    {
        id : "4",
        imgUrl : "https://gulffibre.com/images/poleyster-felts4.jpg"
    },
    {
        id : "3",
        imgUrl : "https://gulffibre.com/images/poleyster-felts5.jpg"
    },

    {
        id : "9",
        imgUrl : "https://gulffibre.com/images/poleyster-felts6.jpg"
    },

    
    {
        id : "7",
        imgUrl : "https://gulffibre.com/images/poleyster-felts8.jpg"
    },

    
    {
        id : "5",
        imgUrl : "https://gulffibre.com/images/poleyster-felts9.jpg"
    },

]

export default slider