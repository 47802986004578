import cargeo from "../../assets/images/cargeo.png"

const slider =[
    {
        id : "1",
        imgUrl : "https://cpimg.tistatic.com/01954824/b/4/Waterproofing-Geotextile.jpg"
    },
    {
        id : "8",
        imgUrl : "https://tiimg.tistatic.com/fp/1/003/195/polypropylene-geotextile-for-subsurface-drainage-004.jpg"
    },
    {
        id : "2",
        imgUrl : "https://media.istockphoto.com/id/544973044/photo/man-lay-geotextiles-in-pit-for-garden-path.webp?b=1&s=170667a&w=0&k=20&c=8rlYjWgCopU_CVcNZAfr8Dr3JWyiKToSh3Nh2b2cvkk="
    },
    {
        id : "4",
        imgUrl : "https://www.devon.gov.uk/communities/wp-content/uploads/sites/88/2016/12/Road-warden-volunteers-300x224.jpg"
    },
    {
        id : "3",
        imgUrl : "http://t2.gstatic.com/images?q=tbn:ANd9GcQ56o8AtmPbttC8YB262gkzSXApcSt8MVDJ8DJKEDi6MWqkIgO4"
    },

    {
        id : "9",
        imgUrl : cargeo
    },

    
   

]

export default slider