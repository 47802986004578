import React from 'react'

const Aboutus = () => {
  return (
    <section className="pt-0 bgc  mt-10" id="aboutus">
      <div className="container pt-14 min-h-screen">
        <div className="md:flex items-center justify-between sm:flex flex-col md:flex-row">
          <div className="w-full md:basis-1/2">

            <h1
              data-aos="fade-up"
              data-aos-duration="1500"
              className=" font-[900] text-[1.8rem] sm:text-[50px] text-primaryColor leading-[35px] sm:leading-[46px] mt-5 w-fit h-fit overflow-hidden"
            >
              Our Company
            </h1>

            <p
              data-aos="fade-left"
              data-aos-duration="1500"
              className="flex gap-2 text-headingColor mt-8 font-[500] sm:pl-10 text-[20px] leading-7  sm:pr-10 text-justify"
            >
              A & M Compillers, was established back in 1999 and had the honour of being the trailblazer
               in introducing Regenerated polyester fiber in Pakistan. We, at A & M Compillers, were the
                very first company in Pakistan to manufacture and initiate our very own plant for regenerated
                 polyester fiber. In 2005, we expanded our operations and added another production line for Polyester
                  Fiber with an impressive capacity of 3600 tons per year. Subsequently, our company further bolstered
                   its capabilities, increasing the annual capacity to a remarkable 12000 tons.
              <br />
              <br />
              In the beginning, our company primarily focused on recycling Polyester waste from esteemed 
              organizations such as ICI, Rupali, and Dewan Salman Fibers. However, in 2001-2, as the prevalence
               of discarded pet bottles became more apparent, we took the initiative to recycle pet bottle flakes.
                This allowed us to not only contribute to the betterment of society but also enabled us to manufacture
                 fiberfil for various home textile articles. Consequently, we have garnered a significant market share
                  in the filtration and home textiles sectors.
            </p>

          </div>
          <div className="basis-1/3 mt-10 sm:mt-0">
            <figure
              data-aos="fade-right"
              data-aos-duration="1500"
              className="flex items-center justify-center">
              <img className='rounded-2xl shadow-xl' src="https://media.istockphoto.com/id/158324613/photo/textile-factory-worker.jpg?s=612x612&w=0&k=20&c=nf6MvtEpobk7HZZ4meGku_DTVEhrifLjwWKZvOAeQxQ=" alt="" />
            </figure>
          </div>
        </div>

      </div>
    </section>
  )
}

export default Aboutus
